<template>
  <div class="mt-4">
    <div>
      <div class="vx-row mb-base mt-base">
        <div class="vx-col sm:w-12/12 w-full">
          <vs-button @click="openDetailKontrak" class="w-full" icon-pack="feather" icon="icon-link">Klik disini untuk melihat detail kontrak</vs-button>
        </div>
      </div>
      <div class="vx-row float-right mt-5">
        <div class="vx-col w-full flex">
          <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
          <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabKontrak',
  props: {
    initData: {
      default: null,
      type: Object
    }
  },
  methods: {
    openDetailKontrak () {
      const route = this.$router.resolve({ name: 'marketing.kontrak.detail', params: { idKontrak: this.initData.header.id_kontrak } })
      window.open(route.href, '_blank')
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.stcApproval.activeTab
      this.$store.commit('approvals/stcApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    },

    next () {
      const currentActiveTab = this.$store.state.approvals.stcApproval.activeTab
      this.$store.commit('approvals/stcApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
